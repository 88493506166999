export function Label({...props}) {
  return (
    <div
      className="inline-block px-4 py-2 my-1 mr-3 text-sm tracking-wide border rounded hover:bg-background-hover bg-background text-foreground"
      {...props}
    >
      {props.children}
    </div>
  );
}
