import cn from 'classnames';
import * as React from 'react';

type TabProps = {
  onClick: (e: React.MouseEvent<HTMLInputElement> & {target: HTMLInputElement}) => void;
  children: React.ReactNode;
  active: string;
  activeColor?: string;
  id: string;
};
export function Tabs({className, ...props}: React.AllHTMLAttributes<HTMLDivElement>) {
  return <div className={cn(`flex`, className)} {...props} />;
}

export function Tab({id, active, onClick, activeColor, children, ...props}: TabProps) {
  const isActive = id === active;

  // TODO: Add cursor pointer when tabs are implemented ready
  return (
    <div
      className={`text-2xl mr-4 lg:text-3xl shrink-0 ${
        isActive ? activeColor || '' : 'text-light-gray'
      }`}
      id={id}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
}

export function useTabs() {
  const [active, setActive] = React.useState('0');
  const setActiveTab = (e: React.MouseEvent<HTMLInputElement> & {target: HTMLInputElement}) => {
    const tabId = e.target.id;
    if (tabId !== active) {
      setActive(tabId);
    }
  };
  return {active, setActiveTab};
}
