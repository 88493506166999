import React from 'react';
import {NetworkStatus} from '@apollo/client';
import {getArticlesVars, useGetArticles} from '@/lib/api/getArticles';
import {Article} from './article/article';
import {ArticleFull} from './article/article-full';
import {Button} from './button';
import {LoadingSpinner} from './loading';

type RecentArticlesProps = {
  className?: string;
  variant?: 'horizontal' | 'small' | 'large';
  showFull?: boolean;
  currentArticleId?: number;
};

export function RecentArticles({
  className,
  currentArticleId,
  showFull = false,
}: RecentArticlesProps) {
  const {loading, error, data, fetchMore, networkStatus} = useGetArticles(getArticlesVars);
  const loadingMoreArticles = networkStatus === NetworkStatus.fetchMore;
  if (error) return <h1>Error loading articles.</h1>;
  if (loading && !loadingMoreArticles) return <LoadingSpinner />;
  if (!data) return null;
  const articles = data.articles.filter((article) => article.id !== currentArticleId);
  const areMoreArticles = articles?.length < 100;

  const handleLoadMore = async () => {
    await fetchMore({
      variables: {
        start: articles?.length + (currentArticleId ? 1 : 0) ?? 0,
        limit: 9, // How many article to fetch
      },
    });
  };

  return (
    <>
      <div className={className ? className : 'mb-3'}>
        {showFull
          ? articles.map((article) => (
              <ArticleFull key={article.id + 2} article={article} isPreview />
            ))
          : articles.map((article) => (
              <Article key={article.id} article={article} variant="horizontal" showAvatar />
            ))}
      </div>
      {areMoreArticles && (
        <div className={`flex justify-center ${showFull ? 'container' : 'my-8'}`}>
          <Button
            label={loadingMoreArticles ? 'Laddar' : 'Ladda fler artiklar'}
            icon={loadingMoreArticles ? 'loading-spinner' : undefined}
            onClick={() => handleLoadMore()}
            outline
          />
        </div>
      )}
    </>
  );
}
