import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import {getImageUrl} from '@/lib/utils';
import {IpoStatus} from '@/lib/utils/ipo-status';
import {Ipo} from '@/types/ipo';

import {IpoStatusLabel} from './ipo-status-label';

type Props = {
  ipo: Ipo;
  status: IpoStatus;
  priority?: boolean;
};
export function IpoCardImg({ipo, status, priority = false}: Props) {
  const {slug, image, title, company} = ipo;
  const img = image?.url;
  const logo = company?.logo;

  return (
    <Link href={`/noteringar/${slug}`}>
      <a className="text-foreground">
        <div className="relative">
          <div className="relative w-full overflow-hidden rounded-md pb-[56.25%]">
            {img && (
              <Image
                src={getImageUrl(img)}
                alt={`IPO ${title}`}
                objectFit="cover"
                layout="fill"
                sizes="(min-width: 700px) 512px, 300px"
                priority={priority}
              />
            )}
          </div>
          <div className="absolute rounded-md top-2 left-2 bg-background/90 text-foreground">
            <IpoStatusLabel status={status} />
          </div>
          {logo && (
            <div className="absolute bottom-3 right-3 max-h-[52px]">
              <div className="relative border-4 border-white bg-white rounded-md w-[110px] h-[54px] max-w-[110px]">
                <Image
                  src={getImageUrl(logo.url)}
                  alt={`${title} logo`}
                  objectFit="contain"
                  layout="fill"
                  priority={priority}
                />
              </div>
            </div>
          )}
        </div>
      </a>
    </Link>
  );
}
