import {IpoStatus} from '@/lib/utils/ipo-status';

export function IpoStatusLabel({
  status,
  hideText = false,
  ...props
}: {
  status: IpoStatus;
  hideText?: boolean;
}) {
  if (status === IpoStatus.Listed) return null;
  const statusBgColor =
    status === IpoStatus.Active
      ? 'bg-primary'
      : status === IpoStatus.Waiting
      ? 'bg-yellow-500'
      : status === IpoStatus.Today
      ? 'bg-pink-300'
      : '';

  return (
    <div
      className="leading-none flex items-center text-[10px] tracking-wide uppercase p-1"
      {...props}
    >
      {!!statusBgColor && (
        <span className="relative w-2 h-2 mr-1.5">
          <span
            className={`absolute inline-flex w-full h-full ${statusBgColor} rounded-full opacity-85`}
            style={{
              animation: 'ping-slow 2.5s cubic-bezier(0, 0, 0.2, 1) infinite',
            }}
          ></span>
          <span className={`relative inline-flex w-2 h-2 ${statusBgColor} rounded-full`}></span>
        </span>
      )}
      {!hideText && status}
    </div>
  );
}
