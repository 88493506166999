import {gql, useQuery} from '@apollo/client';

import {Category} from '@/types/category';

export const GET_CATEGORIES = gql`
  query Categories($limit: Int = 100) {
    categories(limit: $limit) {
      id
      title
      slug
    }
  }
`;

type GetCategoriesResponse = {categories: Category[]};

export function useGetCategories(limit?: number) {
  return useQuery<GetCategoriesResponse>(GET_CATEGORIES, {
    variables: {start: 0, limit},
  });
}
