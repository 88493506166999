import {Button} from '@/components/button';
import {Section} from '@/components/section';
import Image from 'next/image';
import React from 'react';

export function ContactUs() {
  return (
    <Section variant="2xl">
      <div className="flex w-full dark relative items-center h-[480px] text-white bg-black rounded-2xl overflow-hidden overlay-bg-left">
        <Image
          src="/static/images/wallstreet-girl-ipo.jpg"
          objectFit="cover"
          alt="Wallstreet Girl IPO"
          layout="fill"
          sizes="500px"
          quality={50}
        />
        <div className="relative z-10 px-6 md:px-16 dark">
          <h2 className="mb-3 text-4xl font-bold md:text-5xl">Ska ert bolag noteras?</h2>
          <p className="mb-6 text-lg">Ta kontakt med oss för att få hjälp med marknadsföring</p>
          <Button
            size="lg"
            href="https://go.crisp.chat/chat/embed/?website_id=911b272d-4bad-4ac7-8638-ac6a184b26d8"
            variant="secondary"
            label="Kontakta oss"
          />
        </div>
      </div>
    </Section>
  );
}
