import cn from 'classnames';
import Link from 'next/link';
import * as React from 'react';
import {useForm} from 'react-hook-form';
import {FiArrowRight} from 'react-icons/fi';
import {MailIcon} from '@heroicons/react/outline';

import {useFocus} from '../lib/hooks/use-focus';
import {Button, IconButton} from './button';

type NewsletterFormProps = {
  className?: string;
  collapsed?: boolean;
};

// TODO: Do register response without go to landing page
export function NewsletterForm({collapsed = true, className}: NewsletterFormProps) {
  const {
    register,
    watch,
    formState: {errors},
  } = useForm();

  const [inputRef, setInputFocus] = useFocus();
  const [expanded, setExpand] = React.useState(!collapsed);

  const hasInput = watch('EMAIL');
  const hasErrors = !!errors.email && hasInput;
  const showSubmitButton = hasInput && !hasErrors;
  const {ref, ...rest} = register('EMAIL', {
    required: 'Fyll i din email',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Felaktig e-postadress.',
    },
  });

  const handleClickExpand = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setInputFocus();
    setExpand(!expanded);
  };

  return (
    <div className={cn('', className)}>
      <div className="">
        <MailIcon height={32} width={32} className="mb-2 font-bold text-primary" />
        <h1 className="text-2xl font-bold">IPO.se - din plats för noteringar &amp; emissioner</h1>
        <span className="hidden md:block">Nyheter, intervjuer och data samlat på ett ställe</span>
        <div>
          {collapsed && (
            <Button
              variant="secondary"
              size="sm"
              onClick={handleClickExpand}
              icon={expanded ? 'arrow-up' : 'mail'}
              label={expanded ? '' : 'Fyll i din e-postadress'}
            />
          )}
        </div>
        <div className={`overflow-hidden ${expanded ? 'h-auto' : 'h-0'}`}>
          <div className="mx-auto mt-4 text-dark-gray">
            <p className="mb-4">
              Nyhetsbrevet innhåller information och erbjudanden om bolag som ska noteras på börsen,
              bolagsintervjuer, de senaste podcastavsnitten med mera.
            </p>
          </div>
        </div>
      </div>

      {/* https://tailwindcss-custom-forms.netlify.app/ */}
      <div className="relative text-foreground">
        <form
          // onSubmit={handleSubmit(onSubmit)}
          action="https://ipo.us15.list-manage.com/subscribe/post?u=2a5e3200a9fc682f26e6cc729&amp;id=41031a71d4"
          method="post"
        >
          <input
            {...rest}
            type="email"
            placeholder="E-postadress"
            name="EMAIL"
            required
            className={cn(
              `relative text-foreground dark:bg-white/5 text-lg rounded w-full p-3 leading-tight focus:outline-none focus:bg-opacity-10 border border-border-light`,
              hasErrors && 'bg-[#D8000C25] border border-[#D8000C]'
            )}
            ref={(e) => {
              ref(e);
              inputRef.current = e; // Sets focus on input field when newsletter button is pressed.
            }}
          />
          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
          <div style={{position: 'absolute', left: '-5000px'}}>
            <input
              type="text"
              name="b_2a5e3200a9fc682f26e6cc729_41031a71d4"
              aria-hidden
              tabIndex={-1}
              value={-1}
              readOnly
            />
          </div>

          {showSubmitButton && (
            <IconButton
              type="submit"
              className="absolute bg-transparent dark:text-white top-4 right-3 hover:opacity-80"
            >
              <FiArrowRight size={20} />
            </IconButton>
          )}
          {hasErrors && <p className="mt-1 text-sm text-error">{errors.email.message}</p>}
        </form>
        <div className="pt-3">
          Vi lovar att inte sprida din mailadress! - <Link href="/policy">FAQ</Link>
        </div>
      </div>
    </div>
  );
}
