import Link from 'next/link';
import React from 'react';
import {useGetCategories} from '@/lib/api/getCategories';
import {Label} from './label';
import {LoadingSpinner} from './loading';

export function Topics({limit = 100}: {limit?: number}) {
  const {data, loading} = useGetCategories(limit);
  if (loading) return <LoadingSpinner />;
  const categories = data?.categories || [];

  return (
    <div>
      {categories?.map((category) => (
        <Link key={category.id} href={`/kategori/${category.slug}`}>
          <a>
            <Label>{category.title}</Label>
          </a>
        </Link>
      ))}
    </div>
  );
}
