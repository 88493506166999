import {useGetIpos} from '@/lib/api/getIpos';
import {getImageUrl, snakeToNormal} from '@/lib/utils';
import {getIpoStatus} from '@/lib/utils/ipo-status';
import {Ipo} from '@/types/ipo';
import Skeleton from '@mui/material/Skeleton';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import {BsCameraVideoFill} from 'react-icons/bs';
import {FaPodcast} from 'react-icons/fa';
import {DateFormatter} from './date-formatter';
import {IpoCard} from './ipo/ipo-card';
import {IpoStatusLabel} from './ipo/ipo-status-label';
import {Tab, Tabs, useTabs} from './tabs';

export function TimelineItem({item}: {item: Ipo}) {
  const {title, first_trading_day, subscription_period_from, subscription_period_to, company} =
    item;
  const status = getIpoStatus(subscription_period_from, subscription_period_to, first_trading_day);
  const isActive = status === 'Aktiv';
  const img = getImageUrl(company?.logo?.url);
  if (!company) return null;

  return (
    <div className="relative scroll-start-align last:hidden lg:last:block xl:last:hidden">
      <Link key={item.id} href={`/noteringar/${item.slug}`}>
        <a>
          <div className="flex items-center py-3 my-1 overflow-hidden rounded-xl md:last:mr-px">
            <div className="flex justify-center shrink-0 w-16 h-16 p-0.5 mr-4 ml-px overflow-hidden border bg-white rounded-xl">
              {img && (
                <Image
                  src={img}
                  alt={company?.name}
                  className="object-contain mr-3"
                  width="56"
                  height="56"
                />
              )}
            </div>

            {/* Text */}
            <div className="relative overflow-hidden">
              <div className="flex items-center">
                <h2 className="mr-1 font-bold truncate text-foreground text-md">
                  {title.substring(0, 45)}
                </h2>
                <IpoStatusLabel status={status} hideText />
                {company?.interview && <BsCameraVideoFill size={14} className="text-primary" />}
                {!!company?.podcast_episodes?.length && (
                  <FaPodcast size={14} className="text-purple-500" />
                )}
              </div>
              <span className="flex text-xs text-dark-gray">
                <span className="truncate">
                  {isActive ? 'Teckningsperiod' : 'Första handelsdag'}{' '}
                </span>
                <span className="ml-1 shrink-0">
                  <DateFormatter
                    dateString={isActive ? subscription_period_from : first_trading_day}
                    variant="short"
                  />
                  {isActive && (
                    <>
                      <span className="mx-1">&ndash;</span>
                      <DateFormatter dateString={subscription_period_to} variant="short" />
                    </>
                  )}
                </span>
              </span>

              <div className="relative flex max-w-full">
                <span className="text-xs truncate text-dark-gray">
                  {company?.sector} {company?.market && ` ‧ ${snakeToNormal(company?.market)}`}
                </span>
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
}

export function Timeline() {
  const {data} = useGetIpos({limit: 14}); // Sync with pages/index.tsx
  const {active, setActiveTab} = useTabs();
  const items = data?.ipos.slice() ?? [];

  const tabProps = {
    onClick: setActiveTab,
    active,
  };

  const columnCount = {
    '--column-count': 4,
    scrollPadding: '0 16px',
  } as React.CSSProperties;

  return (
    <section className="pt-12 pb-16">
      <div className="mx-auto max-w-screen-2xl">
        {/* Tabs */}
        <div className="container flex items-center mb-4 max-w-screen-2xl">
          <div className="flex items-center justify-between w-full pb-1 mb-4 border-b">
            <Tabs>
              <Tab id="0" {...tabProps}>
                Aktuella noteringar
              </Tab>
              {/* TODO: Implement Nyemissioner */}
              {/* <Tab id="1" {...tabProps}>
                Nyemissioner
              </Tab> */}
            </Tabs>
            <Link href="/noteringar">
              <a className="text-sm font-semibold hover:underline hover:opacity-80">Visa alla</a>
            </Link>
          </div>
        </div>
        <div className="container grid gap-12 mb-8 max-w-none sm:grid-cols-2 lg:grid-cols-4">
          {items.slice(0, 4).map((item) => (
            <IpoCard ipo={item} key={item.id} />
          ))}
        </div>
        <div className="container">
          {items.map((item) => {
            const companyArticles = item.company?.articles || [];
            return companyArticles.map((article) => article.title);
          })}
        </div>
        <div className="container max-w-screen-2xl">
          <h2 className="py-4 text-xl border-t">Fler noteringar</h2>
        </div>
        {/* Grid */}
        {items.length ? (
          <div
            className={`grid gap-x-0 pl-5 sm:pl-6 md:pl-16 gap-y-2 grid-cols-card-full sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-rows-2 overflow-x-auto no-scrollbar`}
            style={columnCount}
          >
            {items.slice(5, items.length).map((item) => (
              <TimelineItem key={item.id} item={item} />
            ))}
          </div>
        ) : (
          <Skeleton />
        )}
        <div className="container flex flex-wrap items-center mt-2 text-xs text-dark-gray">
          <div className="relative shrink-0 w-2 h-2  mr-1.5 rounded-full bg-primary"></div>
          <span className="mr-1.5">
            Aktuell<span className="hidden sm:inline"> (teckningsperiod pågår)</span>
          </span>
          <div className="relative shrink-0 w-2 h-2  mr-1.5  bg-yellow-500 rounded-full"></div>
          <span className="truncate mr-1.5">Inväntar första handelsdag</span>
          <div className="flex items-center">
            <div className="relative shrink-0 w-2 h-2 mr-1.5  bg-pink-300 rounded-full"></div>
            <span className="truncate">Första handelsdag</span>
          </div>
        </div>
      </div>
    </section>
  );
}
