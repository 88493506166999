import {gql, useQuery} from '@apollo/client';
import {ArticleType} from '@/types/article';

export const GET_POPULAR_ARTICLES = gql`
  query articles($start: Int!, $limit: Int!) {
    articles(start: $start, limit: $limit, sort: "published_at:desc", where: {id_null: false}) {
      id
      title
      excerpt
      companies {
        id
        name
      }
      categories {
        id
        slug
        title
      }
      body
      slug
      date
      created_at
      published_at
      source
      image {
        id
        url
        width
        height
      }
    }
  }
`;

type GetPopularArticlesVars = {start?: number; limit?: number};
export const getPopularArticlesVars = {start: 0, limit: 3};

export function useGetPopularArticles(variables?: GetPopularArticlesVars) {
  return useQuery<{articles: ArticleType[]}, GetPopularArticlesVars>(GET_POPULAR_ARTICLES, {
    variables: {...getPopularArticlesVars, ...variables},
  });
}
