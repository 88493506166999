import React from 'react';
import {BsCameraVideoFill} from 'react-icons/bs';
import {FaPodcast} from 'react-icons/fa';

import {Company} from '@/types/company';
import {Icon} from '../icon';

type IpoCardHeaderProps = {
  title: string;
  type?: string;
  company: Company;
};

export function IpoCardHeader({title, type = 'IPO', company}: IpoCardHeaderProps) {
  return (
    <div className="flex items-center">
      <Icon name="se-flag" />
      <h2 className="mr-2 text-2xl truncate">{title}</h2>{' '}
      <div className="flex space-x-1">
        <span className="px-1 text-xs border rounded border-primary text-primary">{type}</span>
        {company?.interview && (
          <div className="">
            <BsCameraVideoFill size={16} className="text-primary" />
          </div>
        )}
        {!!company?.podcast_episodes?.length && <FaPodcast size={14} className="text-purple-500" />}
      </div>
    </div>
  );
}
