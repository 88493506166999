import React from 'react';
import Link from 'next/link';
import {differenceInCalendarDays} from 'date-fns';

import {Ipo} from '@/types/ipo';
import {getIpoStatus, IpoStatus} from '@/lib/utils/ipo-status';

import {DateFormatter} from '../date-formatter';
import {IpoCardImg} from './ipo-card-image';
import {IpoCardStatistics} from './ipo-card-statistics';
import {IpoCardHeader} from './ipo-card-header';

type Props = {ipo: Ipo; priority?: boolean};

export function IpoCard({ipo, priority}: Props) {
  const {
    subscription_period_from,
    subscription_period_to,
    slug,
    title,
    first_trading_day,
    company,
  } = ipo;
  const status = getIpoStatus(subscription_period_from, subscription_period_to, first_trading_day);

  return (
    <div className="relative max-w-full overflow-hidden rounded-md">
      <IpoCardImg ipo={ipo} status={status} priority={priority} />
      <div className="p-2 mt-1">
        <IpoCardStatistics ipo={ipo} />
        {/* Card Header */}
        <Link href={`/noteringar/${slug}`}>
          <a className="text-foreground">
            <IpoCardHeader title={title} company={company} />
          </a>
        </Link>
        {status === IpoStatus.Active && (
          <div className="text-xs">
            {subscription_period_from && subscription_period_to && (
              <span className="text-xs">
                Teckningsperiod: <DateFormatter dateString={subscription_period_from} /> -{' '}
                <DateFormatter dateString={subscription_period_to} />
              </span>
            )}
            {subscription_period_to &&
              differenceInCalendarDays(new Date(subscription_period_to), new Date()) < 3 && (
                <span className="ml-2 text-danger opacity-60">
                  (Stänger om{' '}
                  <DateFormatter variant="shortDistance" dateString={subscription_period_to} />
                  )
                  <br />
                </span>
              )}
          </div>
        )}
        {first_trading_day && (
          <div className="text-xs">
            Första handelsdag: <DateFormatter dateString={first_trading_day} />
          </div>
        )}
      </div>
    </div>
  );
}
