import Image from 'next/image';
import {ClientOnly} from './client-only';

const Sigmastocks = () => (
  <a href="https://track.adtraction.com/t/t?a=1611634095&amp;as=1594804927&amp;t=2&amp;tk=1">
    <Image
      src="https://track.adtraction.com/t/t?a=1611634095&amp;as=1594804927&amp;t=1&amp;tk=1&amp;i=1"
      alt={'Sigmastocks'}
      width="980"
      height="120"
    />
  </a>
);
const Tessin = () => (
  <a href="https://track.adtraction.com/t/t?a=1603718494&amp;as=1594804927&amp;t=2&amp;tk=1">
    <Image
      src="https://track.adtraction.com/t/t?a=1603718494&amp;as=1594804927&amp;t=1&amp;tk=1&amp;i=1"
      alt="tessin"
      width="980"
      height="240"
    />
  </a>
);

type Props = {
  ad?: 'tessin' | 'sigmastocks' | 'savr';
  className?: string;
};

const Savr = () => (
  <a href="https://track.adtraction.com/t/t?a=1481918338&amp;as=1594804927&amp;t=2&amp;tk=1">
    <Image
      src="https://track.adtraction.com/t/t?a=1481918338&amp;as=1594804927&amp;t=1&amp;tk=1&amp;i=1"
      alt="savr"
      width="945"
      height="255"
    />
  </a>
);

export function Ad({ad, ...props}: Props) {
  const randomNr = Math.floor(Math.random() * 3);
  const adStr = ad ?? ['tessin', 'sigmastocks', 'savr'][randomNr];
  const AdBanner = adStr === 'savr' ? Savr : adStr === 'sigmastocks' ? Sigmastocks : Tessin;

  return (
    <ClientOnly>
      <div {...props}>
        <AdBanner />
      </div>
    </ClientOnly>
  );
}

export function HorizontalAd() {
  return (
    <ClientOnly>
      <a href="https://click.adrecord.com/?c=45836&p=1140&g=26119">
        <Image
          src="https://graphics.adrecord.com/45836/1140/26119"
          alt="ad"
          width="300"
          height="600"
        />
      </a>
    </ClientOnly>
  );
}
