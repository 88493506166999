import {gql, useQuery} from '@apollo/client';

import {Ipo} from '@/types/ipo';
import {API_ENDPOINT} from '../config';

export async function getIposCount() {
  const response = await fetch(`${API_ENDPOINT}/ipos/count`);
  return await response.json();
}

export const GET_IPOS = gql`
  query Ipos($start: Int = 0, $limit: Int = 27, $date: Date) {
    ipos(
      start: $start
      limit: $limit
      sort: "first_trading_day:desc"
      where: {first_trading_day_null: false, first_trading_day_gte: $date}
    ) {
      id
      title
      slug
      created_at
      updated_at
      first_trading_day
      subscription_period_from
      subscription_period_to
      # new
      volume
      currency
      price_per_share
      min_subscription
      # old
      company {
        id
        name
        market
        about
        sector
        industry
        interview
        podcast_episodes {
          slug
        }
        logo {
          id
          url
          width
          height
        }
      }
      image {
        id
        url
        width
        height
      }
    }
  }
`;

export const getIposVars = {start: 0, limit: 27};
type GetIposResponse = {ipos: Ipo[]};

export type GetIposVars = {start?: number; limit?: number; date?: string};

export function useGetIpos(variables?: GetIposVars) {
  return useQuery<GetIposResponse, GetIposVars>(GET_IPOS, {
    variables: {...getIposVars, ...variables},
    notifyOnNetworkStatusChange: true,
  });
}
