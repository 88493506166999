import React from 'react';

import {useGetPopularArticles} from '@/lib/api/getPopularArticles';

import {Article} from './article/article';
import {Section} from './section';
import {TrendingUpIcon} from '@heroicons/react/outline';
import {ArticleType} from '@/types/article';

const reducer = (sum: number, article: ArticleType) => sum + (article.image ? 1 : 0);

export function PopularArticles() {
  const {loading, error, data} = useGetPopularArticles({start: 0, limit: 6});
  if (error) return <h1>Error loading articles.</h1>;
  if (loading) return <div>Loading</div>;
  if (!data) return null;

  const articlesWithImages = data.articles.reduce(reducer, 0);
  const articles = data.articles.slice(0, 6 - (articlesWithImages || 3)); // Show 3 articles if no images

  return (
    <Section variant="full" className="mb-12 border-t border-b">
      <div className="container max-w-screen-xl mx-auto my-4">
        <div className="flex items-center mb-4">
          <div className="relative w-6 h-6 p-3 mr-3 border rounded-full border-foreground">
            <TrendingUpIcon width={20} height={20} className="absolute bottom-0 -left-px" />
          </div>
          <h2 className="text-2xl font-semibold">Populära artiklar</h2>
        </div>
        <div className="grid grid-cols-3 gap-8 justify-items-center">
          {articles.map((article, i) => {
            return (
              <Article
                key={i}
                article={article}
                variant="default"
                className={article.image ? `row-span-2` : ''}
              />
            );
          })}
        </div>
      </div>
    </Section>
  );
}
