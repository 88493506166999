import {useGetArticles} from '@/lib/api/getArticles';
import {getFeaturedArticlesVars, useGetFeaturedArticles} from '@/lib/api/getFeaturedArticles';
import {capitalizeFirst} from '@/lib/utils';
import {format} from 'date-fns';
import {sv} from 'date-fns/locale';
import React from 'react';
import {Article} from './article/article';
import {NewsFlash} from './news-flash';

function TodaysDate() {
  const todaysDate = capitalizeFirst(format(new Date(), 'iii d MMM yyyy', {locale: sv}));
  return (
    <div className="mb-3 text-xs font-bold">
      Din plattform för IPO:s &amp; emissioner • {todaysDate}
    </div>
  );
}

export function FeaturedSection() {
  const {data} = useGetFeaturedArticles(getFeaturedArticlesVars);
  const [article1, article2, article3] = data?.articles ?? [];

  const {data: d} = useGetArticles({limit: 5});
  const trendingArticles = d?.articles || Array(5).fill(null);

  return (
    <section className="container mx-auto mt-6 mb-8 max-w-screen-2xl">
      {/* <NewsFlash /> */}
      <div className="grid gap-8 mb-4 xl:grid-cols-12">
        <div className="col-span-full xl:col-span-9">
          <TodaysDate />
          <div className="grid grid-cols-9 gap-8">
            <div className="col-span-full md:col-span-6 md:order-last">
              <Article article={article1} variant="main" priority />
            </div>
            <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-1 col-span-full md:col-span-3">
              <Article article={article2} variant="default" priority />
              <Article article={article3} variant="default" priority />
            </div>
          </div>
        </div>
        <div className="pl-4 -ml-4 col-span-full xl:col-span-3 xl:border-l">
          <h4 className="text-xl font-bold">Trendande nyheter</h4>
          <ol className="grid-cols-2 gap-4 lg:gap-8 md:grid xl:grid-cols-1 border-border-light xl:gap-0">
            {trendingArticles?.map((article, i) => (
              <li key={i} className="border-b last:border-0">
                <Article article={article} variant="small" />
              </li>
            ))}
          </ol>
        </div>
      </div>
    </section>
  );
}
