import {gql, useQuery} from '@apollo/client';
import {ArticleType} from '@/types/article';

export const GET_FEATURED_ARTICLES_QUERY = gql`
  query FeaturedArticles($limit: Int! = 3) {
    articles(limit: $limit, sort: "published_at:desc", where: {featured: true}) {
      id
      title
      excerpt
      featured
      sponsored
      slug
      body
      companies {
        id
        name
        logo {
          id
          url
          height
          width
        }
      }
      categories {
        id
        title
        slug
      }
      tags {
        id
        title
        slug
      }
      date
      created_at
      published_at
      image {
        id
        url
        width
        height
      }
    }
  }
`;

export const getFeaturedArticlesVars = {limit: 3};

export function useGetFeaturedArticles(variables?: {limit?: number}) {
  return useQuery<{articles: ArticleType[]}>(GET_FEATURED_ARTICLES_QUERY, {
    variables: {...getFeaturedArticlesVars, ...variables},
  });
}
