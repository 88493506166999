import React, {useEffect, useState} from 'react';
import {FaIndustry} from 'react-icons/fa';
import {IoIosHeart} from 'react-icons/io';

import {Ipo} from '@/types/ipo';

type Props = {
  ipo: Ipo;
};

export function IpoCardStatistics({ipo}: Props) {
  const [rate, setRate] = useState('0');
  const [votes, setVotes] = useState(0);

  useEffect(() => {
    setRate(((Math.floor(Math.random() * 50) + 1) / 10).toFixed(1));
    setVotes(Math.floor(Math.random() * 250) + 1);
  }, []);

  return (
    <div className="flex items-center justify-between text-sm">
      <div className="flex items-center space-x-2">
        <IoIosHeart size={18} className="mr-1 text-primary" />
        {rate}
        <span className="text-dark-gray">({votes})</span>
      </div>
      <div className="flex space-x-1 text-xs text-dark-gray">
        <div className="flex items-center">
          {/* <IoMdMedical size={iconSize} className="text-[#EB5757CC] mr-1" /> */}
          <FaIndustry size={12} className="mr-1 text-primary opacity-70" />
          <span>
            {ipo.company?.sector} {ipo.company?.industry}
          </span>
        </div>
        {/* <div className="flex items-center">
          <IoIosFlame size={iconSize} className="text-[#EB5757CC]" />
          <span>Populär</span>
        </div> */}
      </div>
    </div>
  );
}
