import {HorizontalAd} from '@/components/ad';
import {ContactUs} from '@/components/contact-us';
import {FeaturedSection} from '@/components/featured-section';
import {Layout} from '@/components/layout';
import {NewsletterForm} from '@/components/newsletter-form';
import {Partners} from '@/components/partners';
import {PopularArticles} from '@/components/popular-articles';
import {RecentArticles} from '@/components/recent-articles';
import {Timeline} from '@/components/timeline';
import {Topics} from '@/components/topics';
import {getArticlesVars, GET_ARTICLES} from '@/lib/api/getArticles';
import {getFeaturedArticlesVars, GET_FEATURED_ARTICLES_QUERY} from '@/lib/api/getFeaturedArticles';
import {GET_IPOS} from '@/lib/api/getIpos';
import {addApolloState, initializeApollo} from '@/lib/apollo-client';
import Link from 'next/link';
import Script from 'next/script';
import React from 'react';

export function MainSection() {
  return (
    <main className="container relative max-w-screen-xl mx-auto lg:grid lg:grid-cols-12">
      {/* Left side */}
      <div className="lg:col-start-1 lg:col-span-7">
        <RecentArticles />
      </div>
      {/* Right sidebar */}
      <div className="lg:col-span-full lg:col-start-9">
        <div className="mb-4">
          <h2 className="text-xl font-semibold">Hitta mer av vad du gillar</h2>
        </div>
        <Topics limit={16} />
        <Link href="/kategorier">
          <a className="block my-5 text-sm font-semibold hover:underline">Se alla ämnen</a>
        </Link>
        <HorizontalAd />
        <div className="my-8 overflow-hidden border rounded-md">
          <h2 className="px-4 py-2 font-semibold border-b dark:bg-black/70">
            Vad är en börsintroduktion (IPO)?
          </h2>
          <p className="px-4 py-4 text-sm">
            En börsintroduktion (IPO) hänvisar till processen att erbjuda aktier i ett privat
            företag till allmänheten i en ny aktieemission. En börsintroduktion tillåter ett företag
            att skaffa kapital från offentliga investerare.
          </p>
          <p className="px-4 pb-4 text-sm">
            Övergången från ett privat till ett publikt företag kan vara en viktig tidpunkt för
            privata investerare att fullt ut realisera vinster från sin investering, eftersom den
            vanligtvis inkluderar en aktiepremie för nuvarande privata investerare.
            {/* TODO: Implement IPO-skola */}
            {/* <br />
              <a href="ipo-skola">Läs mer om börsintroduktioner här</a>. */}
          </p>
        </div>
      </div>
    </main>
  );
}

export default function IndexPage() {
  return (
    <Layout>
      <FeaturedSection />
      <div className="container max-w-screen-md mx-auto">
        {/* <Ad /> */}
        <div id="div-gpt-ad-5549021-1">
          <Script></Script>
        </div>

        <div id="div-gpt-ad-5549021-2">
          <Script></Script>
        </div>

        <div id="div-gpt-ad-5549021-3">
          <Script></Script>
        </div>

        <div id="div-gpt-ad-5549021-4">
          <Script></Script>
        </div>
      </div>
      <Timeline />
      <PopularArticles />
      <MainSection />
      <div
        className="h-[600px] grid place-items-center mt-12 mb-6 bg-right-bottom bg-cover bg-backgrond"
        style={{
          background:
            'url(https://images.unsplash.com/photo-1564483600026-b6338ea296d5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2500&q=80)',
        }}
      >
        <NewsletterForm
          collapsed={false}
          className="absolute grid items-center max-w-screen-lg px-8 py-12 mx-8 lg:gap-12 lg:grid-cols-2 rounded-xl dark:text-white bg-background"
        />
      </div>
      <Partners />
      <ContactUs />
    </Layout>
  );
}

export async function getStaticProps() {
  const apolloClient = initializeApollo();
  await apolloClient.query({query: GET_ARTICLES, variables: getArticlesVars});
  await apolloClient.query({query: GET_IPOS, variables: {limit: 14}});
  await apolloClient.query({
    query: GET_FEATURED_ARTICLES_QUERY,
    variables: getFeaturedArticlesVars,
  });

  return addApolloState(apolloClient, {props: {}, revalidate: 60});
}
