import React from 'react';
import Image from 'next/image';
import cn from 'classnames';

const partners = [
  'cision.svg',
  'talos-media.png',
  'aktier.png',
  'direkt.png',
  'borsdata.svg',
  'borskollen.png',
];

// TODO: Implement links
export function Partners() {
  return (
    <div className="container max-w-screen-lg mx-auto mt-12 mb-8 text-center">
      <h3 className="mb-6 text-sm uppercase text-light-gray">Partners</h3>
      <div className="grid grid-cols-2 gap-12 md:grid-cols-3 lg:grid-cols-6">
        {partners.map((name) => (
          <div
            key={name}
            className="relative h-8 opacity-60 dark:opacity-90 _dark:hover:opacity-100 _hover:opacity-70"
          >
            <Image
              src={`/static/images/partners/${name}`}
              objectFit="contain"
              alt={name}
              className={cn(
                `grayscale dark:filter dark:brightness-100`,
                name === 'borskollen.png'
                  ? 'dark:contrast-50'
                  : name === 'borsdata.svg'
                  ? 'dark:contrast-50 contrast-50'
                  : 'dark:contrast-50'
              )}
              layout="fill"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
